import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { Switch } from "@chakra-ui/switch";
import moment from "moment";
import { useToast, useTheme } from "@chakra-ui/react";
import { Form as FinalForm } from "react-final-form";
import { useLocation } from "@reach/router";
import Section from "../../base/Section";
import Route from "../../base/Route";
import { STATUS, TOTAL_JUMP_PAGES } from "../../../utilities/constants";
import Heading from "../../base/Heading";
import Box from "../../base/Box";
import Table from "../../Table";
import { get as GET, put } from "../../../utilities/http";
import toastMessage from "../../../utilities/toastMessage";
import Button from "../../base/Button";
import Modal from "../../base/Modal";
import Spinner from "../../base/Spinner";
import Icon from "../../Icon";
import AddUpdateCityForm from "../../forms/AddUpdateCityForm";
import search from "../../../utilities/search";
import dashboardConstants from "../../../utilities/dashboard-constants";
import responsiveCss from "../../../utilities/responsiveCss";
import generateSearchString from "../../../utilities/generate-search-string";
import DashboardPaginator from "../../CommonComponents/DashboardPaginator";

const CityListingPage = () => {
  const theme = useTheme();
  const toast = useToast();
  const location = useLocation();
  const [cityData, setCityData] = useState({});
  const [open, setOpen] = useState(false);
  const [city, setCity] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [loading, setLoading] = useState(true);
  const toggleModal = () => {
    setOpen(!open);
  };
  const [sample, setSample] = useState(true);
  const sampleToggle = () => {
    setSample(!sample);
  };
  const { filters, pageCriteria } = dashboardConstants.getCriteria({
    search: location.search,
    type: "cities",
  });
  const totalJumpToPages = responsiveCss(
    TOTAL_JUMP_PAGES.MOBILE,
    TOTAL_JUMP_PAGES.DESKTOP
  );
  useEffect(async () => {
    try {
      const query = dashboardConstants.getQuery({
        filters,
        page: pageCriteria,
      });
      const data = await GET(`/listing-cities?${generateSearchString(query)}`);
      if (data) {
        const filteredCityData = searchValue
          ? search(get(data, "data", []), searchValue, "city")
          : get(data, "data", []);
        setCityData({ ...data, data: filteredCityData });
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [open, sample, location]);
  const tableHeader = [
    { label: "#", value: "index" },
    { label: "Ciudad", value: "city" },
    { label: "Habilitado", value: "status" },
    { label: "Creación", value: "creation" },
    { label: "Acciones", value: "actions" },
  ];

  const pageTitle = "Ciudades";

  const gap = 6;
  const colStyled = {
    flex: 1,
    fontSize: 1,
    maxWidth: "100%",
    mb: gap,
    width: "100%",
  };
  return (
    <Route isPrivate layout="admin">
      <Section sx={{ mx: "auto" }}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            mb: 4,
          }}
        >
          <Heading {...theme.variant.heading}>{pageTitle}</Heading>
          <Button
            onClick={() => {
              setCity(null);
              toggleModal();
            }}
          >
            Añadir ciudad
          </Button>
        </Box>
        <Box>
          <FinalForm
            onSubmit={() => {}}
            render={(formContext) => (
              <form onSubmit={formContext.handleSubmit}>
                <Box
                  as="input"
                  id="search"
                  name="search"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    sampleToggle();
                  }}
                  placeholder="Search"
                  sx={{
                    ...theme.variant.input.primary,
                    mb: 4,
                    mr: 4,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                  }}
                  type="text"
                  wrapperSx={colStyled}
                />
              </form>
            )}
          />
        </Box>
        <Box>
          {loading && <Spinner />}
          {!loading && (
            <>
              <Table
                header={tableHeader}
                renderEmpty="Sin datos"
                rows={get(cityData, "data", []).map((city) => [
                  get(city, "city_id", "-"),
                  get(city, "city", "-"),
                  <Switch
                    key={`status-${city.city_id}`}
                    colorScheme="green"
                    defaultChecked={get(city, "enabled", 0) === STATUS.ACTIVE}
                    onChange={async () => {
                      try {
                        const payLoad = {
                          city: get(city, "city", "-"),
                          city_id: get(city, "city_id", "-"),
                          enabled:
                            get(city, "enabled") === STATUS.ACTIVE
                              ? STATUS.INACTIVE
                              : STATUS.ACTIVE,
                        };
                        const data = await put("/update-city", payLoad);
                        sampleToggle();
                        if (data) {
                          toastMessage({
                            message: "City Edited!",
                            toast,
                            type: "success",
                          });
                        }
                      } catch (e) {
                        toastMessage({
                          message: e.message,
                          toast,
                          type: "error",
                        });
                      }
                    }}
                    variant="primary"
                  />,
                  get(city, "created_at")
                    ? moment(get(city, "created_at")).format("LLLL")
                    : "-",
                  <Box
                    as="button"
                    color="mediumGreen"
                    onClick={() => {
                      toggleModal();
                      setCity(city);
                    }}
                  >
                    <Icon svg="edit" sx={{ width: "18px" }} />
                  </Box>,
                ])}
                tableSx={{
                  borderCollapse: "collapse",
                  overflow: "visible",
                }}
              />
              {cityData.total > 0 && (
                <DashboardPaginator
                  buttonSx={{
                    color: "primary",
                  }}
                  enableJumpToPage
                  filters={filters}
                  pageCriteria={pageCriteria}
                  pageTotal={get(cityData, "data", []).length}
                  total={get(cityData, "total")}
                  totalJumpToPages={totalJumpToPages}
                />
              )}
            </>
          )}
        </Box>
        <Modal
          header={
            <Box sx={{ color: "bgMenu" }}>
              {get(city, "city_id")
                ? "Actualizar ciudad"
                : "Añadir nueva ciudad"}
            </Box>
          }
          onClose={toggleModal}
          open={open}
        >
          <AddUpdateCityForm city={city} toggleModal={toggleModal} />
        </Modal>
      </Section>
    </Route>
  );
};
export default CityListingPage;
