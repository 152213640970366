import React from "react";
import { Router } from "@reach/router";
import CityListingPage from "../components/PageComponents/CityListingPage";

const cities = (props) => (
  <Router>
    <CityListingPage {...props} path="/cities" />
  </Router>
);

export default cities;
