import React from "react";
import { get } from "lodash";
import { useToast } from "@chakra-ui/react";
import { Field, Form as FinalForm } from "react-final-form";

import { post, put } from "../../../utilities/http";
import toastMessage from "../../../utilities/toastMessage";
import Box from "../../base/Box";
import Input from "../../base/Input";
import { required } from "../../../validations";
import FormError from "../../base/FormError";
import Button from "../../base/Button";

const AddUpdateCityForm = ({ toggleModal, city }) => {
  const gap = 4;
  const colStyled = {
    flex: 1,
    fontSize: 1,
    maxWidth: "100%",
    mb: gap,
    width: "100%",
  };
  const colFull = {
    minWidth: "100%",
  };
  const toast = useToast();
  return (
    <FinalForm
      initialValues={{ city: get(city, "city", "") }}
      onSubmit={async (values) => {
        try {
          const payload = {
            ...city,
            city: values.city,
          };
          let data;
          let message;
          if (city) {
            data = await put("/update-city", payload);
            message = data && "City updated!";
          } else {
            data = await post("/add-city", payload);
            message = data && "City Added!";
          }
          if (data) {
            toastMessage({
              message,
              toast,
              type: "success",
            });
            toggleModal();
          }
          /* setCityData([
                  ...cityData,
                  {
                    city: values.city,
                    city_id: Math.floor(Math.random() * 100) + 1,
                    created_at: moment(),
                    enabled: 0,
                  },
                ]);
                toastMessage({
                  message: "City Added!",
                  toast,
                  type: "success",
                });
                toggleModal(); */
        } catch (e) {
          toastMessage({
            message: e.message,
            toast,
            type: "success",
          });
        }
      }}
      render={(formContext) => (
        <form onSubmit={formContext.handleSubmit}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              as={Field}
              clearButton
              component={Input}
              name="city"
              placeholder="Nombre de la ciudad"
              validate={required}
              width="100%"
              wrapperSx={colStyled}
            />
            <FormError>{formContext.submitError}</FormError>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                ...colStyled,
                ...colFull,
              }}
            >
              <Button
                disabled={formContext.pristine || formContext.submitting}
                submitting={formContext.submitting}
                sx={{ mr: 3 }}
                type="submit"
                variant="primary"
              >
                Guardar
              </Button>
              <Button
                onClick={() => {
                  toggleModal();
                }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </form>
      )}
    />
  );
};

export default AddUpdateCityForm;
